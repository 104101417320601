var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"page-projects page-show-project p-0"},[_c('div',{staticClass:"nav-container"},[_c('div',{staticClass:"container"},[_c('ul',{staticClass:"nav nav-pills nav-fill"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":{ name: 'modules.show' },"aria-current":"page"}},[_vm._v("Dati")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":{ name: 'modules.edit' }}},[_vm._v("Modifica")])],1)])])]),_c('div',{staticClass:"container pt-5"},[_c('div',{staticClass:"table-responsive mb-5"},[_c('table',{staticClass:"table table-condensed visea-info-table"},[_c('tbody',[_c('tr',[_c('td',{staticClass:"title"},[_vm._v("Progetto")]),_c('td',[_vm._v(_vm._s(_vm.project.name))])]),_c('tr',[_c('td',{staticClass:"title"},[_vm._v("Nome livello")]),_c('td',[_vm._v(_vm._s(_vm.module.name))])]),_c('tr',[_c('td',{staticClass:"title"},[_vm._v("Note")]),_c('td',[_vm._v(_vm._s(_vm.module.notes))])])])])]),_c('div',{staticClass:"d-flex flex-wrap justify-content-between align-items-center mb-3"},[_c('h2',{staticClass:"mb-0"},[_vm._v("Segnaposti"),(_vm.points.length > 0)?[_vm._v(" ("+_vm._s(_vm.totalResults)+")")]:_vm._e()],2),_c('router-link',{staticClass:"btn btn-primary font-weight-bold text-white",attrs:{"to":{ name: 'points.new' }}},[_vm._v("Aggiungi segnaposto")])],1),(_vm.points && _vm.points.length > 0)?[_c('AwlTable',{staticClass:"mb-3",attrs:{"striped":"","heading":_vm.tableHeadings,"rows":_vm.tableRows},scopedSlots:_vm._u([{key:"column-nb-0",fn:function(ref){
var rowIndex = ref.rowIndex;
return [_vm._v(_vm._s(rowIndex + 1))]}},{key:"column-key-templateId",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm._f("templateId")(value)))]}},{key:"column-key-colour",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"colour-row"},[_c('ColouredSquare',{attrs:{"colour":_vm._f("colour")(value)}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm._f("colourName")(value)))])],1)]}},{key:"actions-content",fn:function(ref){
var row = ref.row;
return [_c('ActionShow',{attrs:{"to":{ name: 'points.show', params: { pointId: row.id } }}}),_c('ActionEdit',{staticClass:"ml-2",attrs:{"to":{ name: 'points.edit', params: { pointId: row.id } }}})]}}],null,false,2933953546)})]:_c('Alert',{attrs:{"status":"primary"}},[_vm._v("Nessun segnaposto presente.")])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }